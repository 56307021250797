/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SITE_CONFIG from '../SiteController/SiteController';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import airlinesName from '../controller/Controller';
import '../resources/css/confirm-style.css';
import Footer2 from '../components/Footer/footer2';
import userIcon from '../resources/images/icons/user-icon.png';
import seatWhite from '../resources/images/icons/seat_icon_white.png';
import background1 from '../resources/images/web-banner-350-2.jpg';
import background11 from '../resources/images/web-banner.jpg';
import background12 from '../resources/images/web-banner-2.jpg';
import background13 from '../resources/images/web-banner-258.jpg';
import background14 from '../resources/images/webbanner123.jpg'
import background2 from '../resources/images/Hotelbnr2.jpg';
import yesMark from '../resources/images/yesmark.png';
import MetaTitle from './metaTitle';
import LaptopBag from '../resources/images/icons/laptop_bag.png';
import TrollyBag from '../resources/images/icons/trolley_bag.png';
import BagIcon from '../resources/images/icons/bagicon-white.png';


function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

function convertMinutesToHoursAndMinutes(minutes) {
  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;
  return `${hours} hrs ${remainingMinutes} min`;
}

export default function Confirmation() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const location = useLocation();
  const { data } = location.state || {};
  const { searchID } = useParams();

  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
    domestics_routes: "+1-833-609-8333",
    domestics_destination: "+1-833-609-7300",
    cruise : "+1-833-609-9933",
    airline: "+1-833-609-8444"
  };
  const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found

  useEffect(() => {
    const updateDisplayNumber = () => {
      const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
      //   console.log(utmData)
      if (utmData?.utmData?.utm_campaign) {
        // console.log("in")
        setUtmCampaign(utmData?.utmData?.utm_campaign);

        // Check if `utm_campaign` exists in the JSON mapping
        const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
        setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
      } else {
        // console.log("out")
        // No UTM data found, use the default number
        setDisplayNumber(defaultNumber);
      }
    };

    // Run initially
    updateDisplayNumber();

    // Set up interval to check every 10 seconds
    const interval = setInterval(updateDisplayNumber, 1000000000000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [jsonMapping]);

  const getBackgroundImage = () => {
    console.log("called", utmCampaign);
    switch (utmCampaign) {
      case "domestics_routes":
        return background11; // Background for domestics_routes
      case "domestics_destination":
        return background12; // Background for domestics_destination
      case "cruise":
          return background13; // Background for domestics_destination 
      case "airline":
          return background14; // Background for domestics_destination  
      default:
        return background1; // Default background
    }
  };

  // const [fromAirportName, setFromAirportName] = useState('Unknown'); // Initialize with a default value
  // const [toAirportName, setToAirportName] = useState('Unknown');
  //console.log(data)
  //const siteID = 2;
  const fetchData = async () => {
    const reloadData = {
      referenceNum: searchID,
      siteId: siteID,
    }
    //console.log(reloadData)
    try {
      //const requestData = searchID && siteID === 2;
      const response = await axios.post(`${apiIP}:8383/api/getConfirmation`, reloadData);
      //data: response.data;
      //console.log(response);
    } catch (error) {
      ////console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Call fetchData when the component mounts (page reloads)
    fetchData();
  }, [searchID]);

  const [airportNames, setAirportNames] = useState({});

  useEffect(() => {
    async function fetchAirportName(airportCode) {
      try {
        // const response = await axios.get(`http://54.70.41.205/flights/CrossAirportList?data=${airportCode}&authcode=farehuts`);
        const response = await axios.get(`https://www.tgsapi.com/flights/CrossAirportList?data=${airportCode}&authcode=farehuts`);
        const matchingAirport = response.data.find((item) => item.airportCode === airportCode);

        // Conditionally set the name based on the match
        const name = matchingAirport ? matchingAirport.airportName : 'Unknown';
        return name;
      } catch (error) {
        ////console.error('Error fetching airport information:', error);
        return 'Unknown';
      }
    }

    if (data?.flightResut) {
      const outboundItems = data.flightResut.outBound || [];
      const inboundItems = data.flightResut.inBound || [];

      const airportCodes = [...outboundItems, ...inboundItems].reduce((codes, item) => {
        if (item.fromAirport) codes.push(item.fromAirport);
        if (item.toAirport) codes.push(item.toAirport);
        return codes;
      }, []);

      const airportNamePromises = airportCodes.map((code) => fetchAirportName(code));

      Promise.all(airportNamePromises).then((names) => {
        const updatedNames = {};
        airportCodes.forEach((code, index) => {
          updatedNames[code] = names[index];
        });
        setAirportNames(updatedNames);
      });
    }
  }, [data]);

  if (!data?.flightResut) {
    return <div>No data available</div>;
  }

  const totalPassengers = (data?.adults && data?.flightResut.adults ? parseInt(data?.flightResut.adults) : 0) + (data?.child && data?.flightResut.child ? parseInt(data?.flightResut.child) : 0) + (data?.infants && data?.flightResut.infants ? parseInt(data?.flightResut.infants) : 0) + (data?.infantsWs && data?.flightResut.infantsWs ? parseInt(data?.flightResut.infantsWs) : 0);

  const totalPriceAdults = (data?.adults * data?.flightResut.fare.adultFare).toFixed(2);
  const totalPriceChild = (data?.child * data?.flightResut.fare.childFare).toFixed(2);
  const totalPriceInfant = ((data?.infants && data?.flightResut.fare.infantFare ? parseInt(data?.infants) : 0) * data?.flightResut.fare.infantFare).toFixed(2);
  const totalPriceInfantWs = ((data?.infantsWs && data?.flightResut.fare.infantWsFare ? parseInt(data?.infantsWs) : 0) * data?.flightResut.fare.infantWsFare).toFixed(2);
  // const totalPriceSeat = (totalPassengers * 7.99);
  // const totalWebCheckInPrice = (totalPassengers * 7.99);

  let totalPrice = data?.flightResut.fare.grandTotal;
  if (data?.ancillaryProduct && data?.ancillaryProduct.length > 0) {
    // Use reduce to sum up the prices of all ancillary products
    totalPrice += data?.ancillaryProduct.reduce((sum, product) => sum + product.price, 0);
  }

  const roundedPrice = totalPrice; // Rounds to 2 decimal places

  const totalTax = data?.flightResut.fare.totalTax;
  const roundedTax = totalTax?.toFixed(2);
  return (
    <>
      <MetaTitle url={`${apiIP}/confirmation`} />
      <div class="container-fluid">

        <div class="row">
          {data ? (
            <div class="col-md-12">
              <br />
              <div class="confirm-detail-container">
                <div class="row wrapper-confirmation">
                  <div class="col-md-9">
                    <div class="comfirm-summry" style={{ marginTop: '65px' }}>
                      {data.bookingStatus !== 'Failure' ? (
                        <div>
                          <h4><img src={yesMark} width="40" height="40" alt="yesMark" /> Thanks You, Your booking is In Progress. </h4>
                          <p>Our associate will reach you shortly. You may also contact us at {displayNumber}. Your booking ID is mentioned below.</p>
                        </div>
                      ) : (
                        <div>
                          <h4><img src={yesMark} width="40" height="40" alt="yesMark" /> Thanks You, Your booking is Confirmed. </h4>
                          <p>We are handling your reservation and will send you confirmation once this has been finished and a ticket has been issued. It would be idea if you take a note that your purchase will be completed only when the ticket has been issued. Fares are not guaranteed until ticketed. In the rare event fares increase, you may opt to cancel your booking by contacting our customer support help desk. Your booking ID is mentioned below.</p>
                        </div>
                      )}
                      <p class="confirm-thanks">Booking Status: In Progress{/*{data.bookingStatus === 'Failure' ? 'In Progress' : 'Success'}*/}</p>
                      <p class="confirm-booked">Booking ID: <span>{data.bookingID}</span></p>
                      {/* <p class="confirm-booked">Booked On: <span>Friday, Sept 15, 2023</span></p> */}
                      <div style={{ clear: 'both' }}></div>
                    </div>
                    <div class="cnfr-marb15">
                      <div class="confirm-title">
                        <h5><img src={userIcon} alt="userIcon" /> Flight Details</h5>
                      </div>
                      {/* <!--next-section--> */}
                      <div class="confirm-boxsadow">

                        <div class="confirm-detail-head">
                          <div class="confirm-head-depar">Departure</div>
                          <div class="confirm-head-Baggage">
                            {/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {data.flightResut.cabinClass === 1 ? 'Economy' : data.flightResut.cabinClass === 2 ? 'Premium Economy' : data.flightResut.cabinClass === 3 ? 'Business' : data.flightResut.cabinClass === 4 ? 'First' : ''}
                            {/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
                          </div>
                        </div>

                        {data?.flightResut?.outBound.map((outBoundItem, index) => (
                          <div key={index} className="confirm-detail-row">
                            <div className="confirm-mobl-respons">
                              <div className="confirm-detail-airline">
                                <img
                                  src={`https://imgfolders.com/farehutz/${outBoundItem.airlineName}.png`}
                                  alt={outBoundItem.airlineName}
                                />
                                <div className="confirm-airline-name-fno">
                                  <div className="confirm-airlineName">
                                    {airlinesName[outBoundItem.airlineName]}
                                  </div>
                                  <div className="confirm-flightno">
                                    Flight No. {outBoundItem.flightNo} | EQP-{outBoundItem.equipmentType}
                                  </div>
                                  {outBoundItem.airlineName !== outBoundItem.opratingAirline ? (
                                    <div className="confirm-flightno">
                                      Operated by {airlinesName[outBoundItem.opratingAirline]}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>

                            <div className="confirm-mobl-respons-next">
                              <div className="confirm-detail-from-to">
                                <div className="confirm-detail-from">
                                  <div className="confirm-from-city">
                                    <strong>{outBoundItem.fromAirport}</strong> - {airportNames[outBoundItem.fromAirport]}
                                  </div>
                                  <div className="confirm-from-time">
                                    <strong>
                                      {formatDateAndTime(outBoundItem.depDate).formattedTimeString}
                                    </strong>{' '}
                                    {formatDateAndTime(outBoundItem.depDate).formattedDateString}
                                  </div>
                                  {/* {
  outBoundItem.fromAirport &&
  outBoundItem.find(a => a.airportCode === outBoundItem.fromAirport)?.airportName !==
    (selectedFlight.outBound[0]?.toAirport &&
      airport.find(a => a.airportCode === selectedFlight.outBound[0]?.toAirport)?.airportName) && (
        <p className="cnfrm-arrivnext">Airport Changes</p>
                              )
} */}

                                </div>
                                <div className="confirm-ddinfo-durastop">
                                  <div className="confirm-duration">
                                    {`${convertMinutesToHoursAndMinutes(outBoundItem.eft)}`}
                                  </div>
                                  <div className="confirm-Detailsstop-graph"></div>
                                </div>
                                <div className="confirm-detail-to">
                                  <div className="confirm-from-city">
                                    <strong>{outBoundItem.toAirport}</strong> - {airportNames[outBoundItem.toAirport]}
                                  </div>
                                  <div className="confirm-from-time">
                                    <strong>
                                      {formatDateAndTime(outBoundItem.reachDate).formattedTimeString}
                                    </strong>{' '}
                                    {formatDateAndTime(outBoundItem.depDate).formattedDateString !==
                                      formatDateAndTime(outBoundItem.reachDate).formattedDateString ? (
                                      <sup>+1</sup>
                                    ) : null}{' '}
                                    {/* <sup>+1</sup>{' '} */}
                                    {formatDateAndTime(outBoundItem.reachDate).formattedDateString}
                                  </div>
                                  {formatDateAndTime(outBoundItem.depDate).formattedDateString !==
                                    formatDateAndTime(outBoundItem.reachDate).formattedDateString ? (
                                    <p className="cnfrm-arrivnext">Arrival next day</p>
                                  ) : null}
                                </div>
                              </div>
                              {outBoundItem.layOverTime > 0 ? (
                                <h2 className="confirm-layover-divider">
                                  <span>
                                    <i className="fa fa-clock"></i>(
                                    {convertMinutesToHoursAndMinutes(outBoundItem.layOverTime)}) At{' '}
                                    {airportNames[outBoundItem.toAirport]} ({outBoundItem.toAirport})
                                  </span>
                                </h2>
                              ) : null}
                            </div>
                          </div>
                        ))}

                        <div class="payment-detail-footer">
                          <div class="confirm-head-Baggage">
                            <p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(data.flightResut.outBound[0].estimateTime)}`}</p>
                          </div>
                        </div>

                      </div>
                      {/* <!--next-section close-here-->							
							<!--next itnery--> */}
                      {data?.flightResut?.inBound ? (
                        <div class="confirm-boxsadow">
                          <div class="confirm-detail-head">
                            <div class="confirm-head-depar">Return</div>
                            <div class="confirm-head-Baggage">
                              {/*<strong>Baggage:</strong> 2pc | */}<strong>Cabin</strong> {data.flightResut.cabinClass === 1 ? 'Economy' : data.flightResut.cabinClass === 2 ? 'Premium Economy' : data.flightResut.cabinClass === 3 ? 'Business' : data.flightResut.cabinClass === 4 ? 'First' : ''}
                              {/* <p><strong>Brand Name:</strong> Basic Economy</p> */}
                            </div>
                          </div>





                          {data?.flightResut?.inBound.map((inBoundItem, index) => (

                            <div key={index} className="confirm-detail-row">
                              <div className="confirm-mobl-respons">
                                <div className="confirm-detail-airline">
                                  <img
                                    src={`https://imgfolders.com/farehutz/${inBoundItem.airlineName}.png`}
                                    alt={inBoundItem.airlineName}
                                  />
                                  <div className="confirm-airline-name-fno">
                                    <div className="confirm-airlineName">
                                      {airlinesName[inBoundItem.airlineName]}
                                    </div>
                                    <div className="confirm-flightno">
                                      Flight No. {inBoundItem.flightNo} | EQP-{inBoundItem.equipmentType}
                                    </div>
                                    {inBoundItem.airlineName !== inBoundItem.opratingAirline ? (
                                      <div className="confirm-flightno">
                                        Operated by {airlinesName[inBoundItem.opratingAirline]}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="confirm-mobl-respons-next">
                                <div className="confirm-detail-from-to">
                                  <div className="confirm-detail-from">
                                    <div className="confirm-from-city">
                                      <strong>{inBoundItem.fromAirport}</strong> - {airportNames[inBoundItem.fromAirport]}
                                    </div>
                                    <div className="confirm-from-time">
                                      <strong>
                                        {formatDateAndTime(inBoundItem.depDate).formattedTimeString}
                                      </strong>{' '}
                                      {formatDateAndTime(inBoundItem.depDate).formattedDateString}
                                    </div>
                                    {/* <p className="cnfrm-arrivnext">Airport Changes</p> */}
                                  </div>
                                  <div className="confirm-ddinfo-durastop">
                                    <div className="confirm-duration">
                                      {`${convertMinutesToHoursAndMinutes(inBoundItem.eft)}`}
                                    </div>
                                    <div className="confirm-Detailsstop-graph"></div>
                                  </div>
                                  <div className="confirm-detail-to">
                                    <div className="confirm-from-city">
                                      <strong>{inBoundItem.toAirport}</strong> - {airportNames[inBoundItem.toAirport]}
                                    </div>
                                    <div className="confirm-from-time">
                                      <strong>
                                        {formatDateAndTime(inBoundItem.reachDate).formattedTimeString}
                                      </strong>{' '}
                                      {formatDateAndTime(inBoundItem.depDate).formattedDateString !==
                                        formatDateAndTime(inBoundItem.reachDate).formattedDateString ? (
                                        <sup>+1</sup>
                                      ) : null}{' '}
                                      {/* <sup>+1</sup>{' '} */}
                                      {formatDateAndTime(inBoundItem.reachDate).formattedDateString}
                                    </div>
                                    {formatDateAndTime(inBoundItem.depDate).formattedDateString !==
                                      formatDateAndTime(inBoundItem.reachDate).formattedDateString ? (
                                      <p className="cnfrm-arrivnext">Arrival next day</p>
                                    ) : null}
                                  </div>
                                </div>
                                {inBoundItem.layOverTime > 0 ? (
                                  <h2 className="confirm-layover-divider">
                                    <span>
                                      <i className="fa fa-clock"></i>(
                                      {convertMinutesToHoursAndMinutes(inBoundItem.layOverTime)}) At{' '}
                                      {airportNames[inBoundItem.toAirport]} ({inBoundItem.toAirport})
                                    </span>
                                  </h2>
                                ) : null}
                              </div>
                            </div>
                          ))}






                          <div class="payment-detail-footer">
                            <div class="confirm-head-Baggage">
                              <p>Total Trip Duration: {`${convertMinutesToHoursAndMinutes(data.flightResut.inBound[0].estimateTime)}`}</p>
                            </div>
                          </div>



                          <div class="disclaimerbg">
                            <div class="row">
                              <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                              <div class="col-md-11 confirm-seat-disc"> Most airlines charge baggage fees, check the Baggage fee for complete details.</div>
                            </div>
                          </div>

                        </div>
                      ) : null}
                      {/* <!--next-section close-here--> */}



                    </div>


                    <div class="cnfr-marb15">
                      <div class="confirm-title">
                        <h5><img src={userIcon} alt="userIcon" /> Traveler Details</h5>
                      </div>
                      <div class="confirm-boxsadow">

                        <table class="tblwidth">
                          <tr class="tblhead">
                            <td class="tblpadd">S.No</td>
                            <td class="tblpadd">Passenger(s) Name</td>
                            {/* <td class="tblpadd">Middle Name</td> */}
                            {/* <td class="tblpadd">Last Name</td> */}
                            <td class="tblpadd">Gender</td>
                            <td class="tblpadd">Date of Birth</td>
                          </tr>

                          {data.passengerDetails.map((passenger, index) => (
                            <tr className="tblrow" key={index}>
                              <td className="tblpadd">{index + 1}.</td>
                              {/* <td className="tblpadd">{passenger.title}</td> */}
                              <td className="tblpadd">{passenger.title}. {passenger.firstName}{" "}{passenger.middleName}{" "}{passenger.lastName}</td>
                              {/* <td className="tblpadd">{passenger.middleName}</td> */}
                              {/* <td className="tblpadd">{passenger.lastName}</td> */}
                              <td className="tblpadd">{passenger.gender === 1 ? "Male" : "Female"}</td>
                              <td className="tblpadd">
                                {`${passenger.dobDay}-${passenger.dobMonth}-${passenger.dobYear}`}
                              </td>
                            </tr>
                          ))}

                          {/* <tr class="tblrow">
                        <td class="tblpadd">1.</td>
                        <td class="tblpadd">Mrs. Anjali</td>
						<td class="tblpadd">Trivedi</td>
						<td class="tblpadd">Duve</td>
                        <td class="tblpadd">Male</td>
                        <td class="tblpadd">05-nov-2000</td>
                    </tr>		 */}

                        </table>

                        <div class="disclaimerbg">
                          <div class="row">
                            <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                            <div class="col-md-11 confirm-seat-disc"> Any specific request you may have will be sent to the airline(s). Please contact your airline(s) directly, prior to your departure date, to confirm
                              any Special requests.</div>
                          </div>
                        </div>

                      </div>
                    </div>
                    {data?.ancillaryProduct[0]?.productId === 5 ? (
                      <div class="cnfr-marb15">
                        <div class="confirm-title">
                          <h5><img src={BagIcon} alt="" /> Selected Baggage</h5>
                        </div>



                        <div class="confirm-boxsadow">
                          {data?.ancillaryProduct[0]?.supplierNumber === "Depart" || data?.ancillaryProduct[1]?.supplierNumber === "Depart" || data?.ancillaryProduct[2]?.supplierNumber === "Depart" || data?.ancillaryProduct[3]?.supplierNumber === "Depart" ? (
                            <div class="flex-container">
                              <div class="box_es_headng">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>
                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}>
                                        {data?.flightResut?.outBound[1]?.airlineName && data?.flightResut?.outBound[0]?.airlineName !== data?.flightResut?.outBound[1]?.airlineName ? (
                                          <>
                                            <img src={`https://imgfolders.com/farehutz/${data?.flightResut?.outBound[0]?.airlineName}.png`} width="32" alt={data?.flightResut?.outBound[0]?.airlineName} />
                                            <svg width="25" height="21" style={{ position: 'absolute', marginLeft: '166px', marginTop: '-29px' }} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.92914 24C9.93424 23.4991 9.94556 22.9982 9.94103 22.4979C9.93933 22.3396 9.98691 22.2391 10.1172 22.1442C10.785 21.6573 11.4477 21.1632 12.1036 20.6606C12.184 20.5989 12.2582 20.4714 12.2588 20.3737C12.2679 18.3904 12.2656 16.4076 12.265 14.4243C12.265 14.3727 12.2582 14.321 12.2514 14.2295C9.16958 15.1858 6.10246 16.1375 3.00475 17.0983C3.00475 16.7895 3.00475 16.5076 3.00475 16.2257C3.00475 15.8214 3.01325 15.4171 3.00079 15.0134C2.99569 14.8489 3.05516 14.7618 3.1928 14.677C6.12512 12.8622 9.0529 11.0389 11.9875 9.228C12.2004 9.09661 12.2735 8.96016 12.2718 8.71421C12.2605 6.85107 12.2962 4.98682 12.2543 3.12424C12.2293 2.01243 12.7238 1.25999 13.7734 1.00056L14.2254 1.00056C14.6049 1.10725 14.9623 1.25269 15.2392 1.54749C15.5921 1.92258 15.7377 2.36675 15.736 2.87717C15.7298 4.85261 15.736 6.82861 15.7292 8.80405C15.7286 8.98206 15.7824 9.08369 15.9359 9.17915C18.8818 11.0013 21.8227 12.8307 24.7691 14.6523C24.9357 14.7556 25.0048 14.859 24.9997 15.0583C24.9844 15.6563 24.994 16.2555 24.994 16.8541C24.994 16.9265 24.994 16.9984 24.994 17.0983C21.9008 16.1387 18.8394 15.1886 15.7468 14.2284C15.7411 14.3351 15.7337 14.4131 15.7337 14.4912C15.7332 16.4368 15.7349 18.3825 15.7298 20.3282C15.7298 20.4871 15.7773 20.5859 15.907 20.6808C16.5686 21.1637 17.2194 21.6607 17.881 22.1436C18.0113 22.2391 18.0588 22.339 18.0571 22.4974C18.052 22.9982 18.0639 23.4986 18.069 23.9994C18.039 23.9994 18.009 23.9994 17.9784 23.9994C17.9603 23.9871 17.9439 23.9686 17.924 23.9629C16.6604 23.603 15.3967 23.2419 14.1308 22.8893C14.0368 22.8629 13.9206 22.8764 13.8238 22.9028C13.1866 23.0774 12.5511 23.2588 11.9161 23.4424C11.284 23.6255 10.6519 23.8141 10.0198 24C9.98975 24 9.95973 24 9.92914 24Z" fill="#ADB8BF" />
                                              <path d="M6.2985 20.9546C6.30314 20.4992 6.31344 20.0439 6.30932 19.589C6.30777 19.445 6.35103 19.3537 6.46947 19.2674C7.07659 18.8248 7.67908 18.3755 8.27539 17.9186C8.34851 17.8625 8.41597 17.7466 8.41648 17.6578C8.42472 15.8547 8.42266 14.0521 8.42215 12.249C8.42215 12.202 8.41597 12.155 8.40979 12.0718C5.60796 12.9412 2.81952 13.8065 0.00326767 14.68C0.00326765 14.3992 0.00326764 14.1429 0.00326763 13.8867C0.00326761 13.5191 0.0109918 13.1515 -0.000337051 12.7845C-0.00497159 12.6349 0.0490979 12.5558 0.17423 12.4787C2.84011 10.8288 5.50188 9.17115 8.16982 7.52478C8.36344 7.40532 8.42987 7.28127 8.42833 7.05767C8.41803 5.36381 8.45047 3.66894 8.41236 1.9756C8.38971 0.964807 8.83925 0.280732 9.79345 0.0448798L10.2044 0.0448798C10.5494 0.141875 10.8743 0.274095 11.1261 0.54211C11.4469 0.883126 11.5793 1.28693 11.5777 1.75098C11.5721 3.54693 11.5777 5.34339 11.5716 7.13935C11.5711 7.30118 11.62 7.39358 11.7595 7.48036C14.4378 9.13695 17.1114 10.8002 19.7901 12.4562C19.9415 12.5502 20.0044 12.6441 19.9997 12.8253C19.9858 13.369 19.9946 13.9137 19.9946 14.4579C19.9946 14.5238 19.9946 14.5891 19.9946 14.68C17.1824 13.8075 14.3991 12.9438 11.5875 12.0708C11.5824 12.1678 11.5757 12.2388 11.5757 12.3097C11.5752 14.0786 11.5767 15.8475 11.5721 17.6164C11.5721 17.7609 11.6153 17.8507 11.7333 17.937C12.3347 18.376 12.9264 18.8278 13.5279 19.2669C13.6463 19.3537 13.6895 19.4445 13.688 19.5885C13.6834 20.0439 13.6942 20.4987 13.6988 20.9541C13.6715 20.9541 13.6442 20.9541 13.6164 20.9541C13.5999 20.9428 13.585 20.926 13.567 20.9209C12.4181 20.5937 11.2693 20.2654 10.1184 19.9448C10.0329 19.9208 9.92734 19.9331 9.83928 19.9571C9.25997 20.1158 8.6822 20.2807 8.10494 20.4477C7.53026 20.6141 6.95558 20.7856 6.3809 20.9546C6.3536 20.9546 6.32631 20.9546 6.2985 20.9546Z" fill="#E2E2E2" />
                                            </svg>
                                          </>
                                        ) : (<img src={`https://imgfolders.com/farehutz/${data?.flightResut?.outBound[0]?.airlineName}.png`} width="32" alt={data?.flightResut?.outBound[0]?.airlineName} />)}</td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.flightResut?.outBound[1]?.airlineName && data?.flightResut?.outBound[0]?.airlineName !== data?.flightResut?.outBound[1]?.airlineName ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none', textAlign: 'justify' }}>
                                          <b style={{ fontSize: '14px', color: '#2276b2' }}>Departure</b><br />
                                          {airlinesName[data?.flightResut?.outBound[0]?.airlineName]} & {airlinesName[data?.flightResut?.outBound[1]?.airlineName]}
                                        </td>
                                      ) : (<td style={{ lineHeight: '1.8em', border: 'none', textAlign: 'justify' }}>
                                        <b style={{ fontSize: '14px', color: '#2276b2' }}>Departure</b><br />
                                        {airlinesName[data?.flightResut?.outBound[0]?.airlineName]}
                                      </td>)}
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div class="box_es">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>

                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}><img src={LaptopBag} alt='' /></td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.ancillaryProduct.some(product => product.productId === 5) ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none'/*, textAlign: 'justify'*/ }}>
                                          <b style={{ fontSize: '14px' }}>Carry-on Bag</b><br />
                                          {data?.ancillaryProduct.map(product => product.productId === 5 ? product.fullName : null)}
                                        </td>
                                      ) : null}
                                    </tr>
                                    </tbody></table>
                                </div>
                              </div>
                              <div class="box_es">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>
                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}><img src={TrollyBag} alt='' /></td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.ancillaryProduct.some(product => product.productId === 6) ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none'/*, textAlign: 'justify'*/ }}>
                                          <b style={{ fontSize: '14px' }}>Checked Bag</b><br />
                                          {data?.ancillaryProduct.map(product => product.productId === 6 ? product.fullName : null)}</td>
                                      ) : null}
                                    </tr>
                                    </tbody></table>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {data?.ancillaryProduct[0]?.supplierNumber === "Return" || data?.ancillaryProduct[1]?.supplierNumber === "Return" || data?.ancillaryProduct[2]?.supplierNumber === "Return" || data?.ancillaryProduct[3]?.supplierNumber === "Return" ? (
                            <div class="flex-container">
                              <div class="box_es_headng">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>
                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}>
                                        {data?.flightResut?.inBound[1]?.airlineName && data?.flightResut?.inBound[0]?.airlineName !== data?.flightResut?.inBound[1]?.airlineName ? (
                                          <>
                                            <img src={`https://imgfolders.com/farehutz/${data?.flightResut?.inBound[0]?.airlineName}.png`} width="32" alt={data?.flightResut?.inBound[0]?.airlineName} />
                                            <svg width="25" height="21" style={{ position: 'absolute', marginLeft: '166px', marginTop: '-29px' }} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M9.92914 24C9.93424 23.4991 9.94556 22.9982 9.94103 22.4979C9.93933 22.3396 9.98691 22.2391 10.1172 22.1442C10.785 21.6573 11.4477 21.1632 12.1036 20.6606C12.184 20.5989 12.2582 20.4714 12.2588 20.3737C12.2679 18.3904 12.2656 16.4076 12.265 14.4243C12.265 14.3727 12.2582 14.321 12.2514 14.2295C9.16958 15.1858 6.10246 16.1375 3.00475 17.0983C3.00475 16.7895 3.00475 16.5076 3.00475 16.2257C3.00475 15.8214 3.01325 15.4171 3.00079 15.0134C2.99569 14.8489 3.05516 14.7618 3.1928 14.677C6.12512 12.8622 9.0529 11.0389 11.9875 9.228C12.2004 9.09661 12.2735 8.96016 12.2718 8.71421C12.2605 6.85107 12.2962 4.98682 12.2543 3.12424C12.2293 2.01243 12.7238 1.25999 13.7734 1.00056L14.2254 1.00056C14.6049 1.10725 14.9623 1.25269 15.2392 1.54749C15.5921 1.92258 15.7377 2.36675 15.736 2.87717C15.7298 4.85261 15.736 6.82861 15.7292 8.80405C15.7286 8.98206 15.7824 9.08369 15.9359 9.17915C18.8818 11.0013 21.8227 12.8307 24.7691 14.6523C24.9357 14.7556 25.0048 14.859 24.9997 15.0583C24.9844 15.6563 24.994 16.2555 24.994 16.8541C24.994 16.9265 24.994 16.9984 24.994 17.0983C21.9008 16.1387 18.8394 15.1886 15.7468 14.2284C15.7411 14.3351 15.7337 14.4131 15.7337 14.4912C15.7332 16.4368 15.7349 18.3825 15.7298 20.3282C15.7298 20.4871 15.7773 20.5859 15.907 20.6808C16.5686 21.1637 17.2194 21.6607 17.881 22.1436C18.0113 22.2391 18.0588 22.339 18.0571 22.4974C18.052 22.9982 18.0639 23.4986 18.069 23.9994C18.039 23.9994 18.009 23.9994 17.9784 23.9994C17.9603 23.9871 17.9439 23.9686 17.924 23.9629C16.6604 23.603 15.3967 23.2419 14.1308 22.8893C14.0368 22.8629 13.9206 22.8764 13.8238 22.9028C13.1866 23.0774 12.5511 23.2588 11.9161 23.4424C11.284 23.6255 10.6519 23.8141 10.0198 24C9.98975 24 9.95973 24 9.92914 24Z" fill="#ADB8BF" />
                                              <path d="M6.2985 20.9546C6.30314 20.4992 6.31344 20.0439 6.30932 19.589C6.30777 19.445 6.35103 19.3537 6.46947 19.2674C7.07659 18.8248 7.67908 18.3755 8.27539 17.9186C8.34851 17.8625 8.41597 17.7466 8.41648 17.6578C8.42472 15.8547 8.42266 14.0521 8.42215 12.249C8.42215 12.202 8.41597 12.155 8.40979 12.0718C5.60796 12.9412 2.81952 13.8065 0.00326767 14.68C0.00326765 14.3992 0.00326764 14.1429 0.00326763 13.8867C0.00326761 13.5191 0.0109918 13.1515 -0.000337051 12.7845C-0.00497159 12.6349 0.0490979 12.5558 0.17423 12.4787C2.84011 10.8288 5.50188 9.17115 8.16982 7.52478C8.36344 7.40532 8.42987 7.28127 8.42833 7.05767C8.41803 5.36381 8.45047 3.66894 8.41236 1.9756C8.38971 0.964807 8.83925 0.280732 9.79345 0.0448798L10.2044 0.0448798C10.5494 0.141875 10.8743 0.274095 11.1261 0.54211C11.4469 0.883126 11.5793 1.28693 11.5777 1.75098C11.5721 3.54693 11.5777 5.34339 11.5716 7.13935C11.5711 7.30118 11.62 7.39358 11.7595 7.48036C14.4378 9.13695 17.1114 10.8002 19.7901 12.4562C19.9415 12.5502 20.0044 12.6441 19.9997 12.8253C19.9858 13.369 19.9946 13.9137 19.9946 14.4579C19.9946 14.5238 19.9946 14.5891 19.9946 14.68C17.1824 13.8075 14.3991 12.9438 11.5875 12.0708C11.5824 12.1678 11.5757 12.2388 11.5757 12.3097C11.5752 14.0786 11.5767 15.8475 11.5721 17.6164C11.5721 17.7609 11.6153 17.8507 11.7333 17.937C12.3347 18.376 12.9264 18.8278 13.5279 19.2669C13.6463 19.3537 13.6895 19.4445 13.688 19.5885C13.6834 20.0439 13.6942 20.4987 13.6988 20.9541C13.6715 20.9541 13.6442 20.9541 13.6164 20.9541C13.5999 20.9428 13.585 20.926 13.567 20.9209C12.4181 20.5937 11.2693 20.2654 10.1184 19.9448C10.0329 19.9208 9.92734 19.9331 9.83928 19.9571C9.25997 20.1158 8.6822 20.2807 8.10494 20.4477C7.53026 20.6141 6.95558 20.7856 6.3809 20.9546C6.3536 20.9546 6.32631 20.9546 6.2985 20.9546Z" fill="#E2E2E2" />
                                            </svg>
                                          </>
                                        ) : (<img src={`https://imgfolders.com/farehutz/${data?.flightResut?.inBound[0]?.airlineName}.png`} width="32" alt={data?.flightResut?.inBound[0]?.airlineName} />)}</td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.flightResut?.inBound[1]?.airlineName && data?.flightResut?.inBound[0]?.airlineName !== data?.flightResut?.inBound[1]?.airlineName ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none', textAlign: 'justify' }}>
                                          <b style={{ fontSize: '14px', color: '#2276b2' }}>Return</b><br />
                                          {airlinesName[data?.flightResut?.inBound[0]?.airlineName]} & {airlinesName[data?.flightResut?.inBound[1]?.airlineName]}
                                        </td>
                                      ) : (<td style={{ lineHeight: '1.8em', border: 'none', textAlign: 'justify' }}>
                                        <b style={{ fontSize: '14px', color: '#2276b2' }}>Return</b><br />
                                        {airlinesName[data?.flightResut?.inBound[0]?.airlineName]}
                                      </td>)}
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }} ></td>
                                    </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div class="box_es">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>
                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}><img src={LaptopBag} alt='' /></td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.ancillaryProduct.some(product => product.productId === 7) ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none'/*, textAlign: 'justify'*/ }}>
                                          <b style={{ fontSize: '14px' }}>Carry-on Bag</b><br />
                                          {data?.ancillaryProduct.map(product => product.productId === 7 ? product.fullName : null)}
                                        </td>
                                      ) : null}
                                    </tr>
                                    </tbody></table>
                                </div>
                              </div>
                              <div class="box_es">
                                <div class="bgbag" style={{ borderBottom: '1px solid #ddd', padding: '5px' }}>
                                  <table width="100%" cellpadding="0" cellspacing="0" border="0" style={{ fontSize: '12px', border: 'none' }}>
                                    <tbody><tr>
                                      <td width="60" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}><img src={TrollyBag} alt='' /></td>
                                      <td class="w10" style={{ border: 'none', borderCollapse: 'none', backgroundColor: 'none' }}></td>
                                      {data?.ancillaryProduct.some(product => product.productId === 8) ? (
                                        <td style={{ lineHeight: '1.8em', border: 'none'/*, textAlign: 'justify'*/ }}>
                                          <b style={{ fontSize: '14px' }}>Checked Bag</b><br />
                                          {data?.ancillaryProduct.map(product => product.productId === 8 ? product.fullName : null)}</td>
                                      ) : null}
                                    </tr>
                                    </tbody></table>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div class="disclaimerbg">
                            <div class="row">
                              <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                              <div class="col-md-11 confirm-seat-disc"> Any specific request you may have will be sent to the airline(s). Please contact your airline(s) directly, prior to your departure date, to confirm
                                special regrading Special requests.</div>
                            </div>
                          </div>
                        </div>


                      </div>
                    ) : null}

                    <div class="cnfr-marb15">
                      <div class="confirm-title">
                        <h5 class="mar-b">
                          <img src={seatWhite} alt="seatWhite" /> Price Summary
                        </h5>
                      </div>
                      <div class="confirm-boxsadow">

                        <div class="confirm-fare-area">
                          <ul>
                            <li><span>Adult ({(data.adults)}x${data.flightResut.fare.adultFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.adultFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceAdults.split('.')[0]}.<sup>{totalPriceAdults.split('.')[1]}</sup></span> </li>
                            {data.child ? (
                              <li><span>Child ({(data.child)}x${data.flightResut.fare.childFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.childFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceChild.split('.')[0]}.<sup>{totalPriceChild.split('.')[1]}</sup></span> </li>
                            ) : null}
                            {data.infants ? (
                              <li><span>Infant <span class="fonsmal">Lap</span> ({(data.infants)}x${data.flightResut.fare.infantFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.infantFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceInfant.split('.')[0]}.<sup>{totalPriceInfant.split('.')[1]}</sup></span> </li>
                            ) : null}
                            {data.infantsWs ? (
                              <li><span>Infant <span class="fonsmal">seat</span> ({(data.infantsWs)}x${data.flightResut.fare.infantWsFare.toFixed(2).split('.')[0]}.<sup>{data.flightResut.fare.infantWsFare.toFixed(2).split('.')[1]}</sup>)</span>  <span>${totalPriceInfantWs.split('.')[0]}.<sup>{totalPriceInfantWs.split('.')[1]}</sup></span> </li>
                            ) : null}
                            <li><span>Taxes</span>  <span>${roundedTax.split('.')[0]}.<sup>{roundedTax.split('.')[1]}</sup></span> </li>
                            {data.ancillaryProduct && data.ancillaryProduct.length > 0 ? (
                              <ul>
                                {data.ancillaryProduct.map((product, index) => (
                                  <li key={index}>
                                    <span>{product.fullName}</span>
                                    <span>${product.price.toString().split('.')[0]}.<sup>{product.price.toString().split('.')[1]}</sup></span>
                                  </li>
                                ))}
                              </ul>
                            ) : null}

                            {/* <li><span>Seat </span> <span>$35.<sup>25</sup></span> </li>

						<li><span>Baggage </span> <span>$30.<sup>00</sup></span> </li>

						<li><span>Flight Alert </span> <span>$35.<sup>00</sup></span> </li>	

						<li style={{borderBottom: '0px'}}><span>Bundle </span> <span>$30.<sup>00</sup></span> </li>	 */}

                          </ul>

                          <div class="pay-booked">
                            <h6> Total Price : <div style={{ float: 'right' }}>$<span>{(roundedPrice).toFixed(2).toString().split('.')[0]}.<sup>{(roundedPrice).toFixed(2).toString().split('.')[1]}</sup></span></div></h6>



                          </div>

                        </div>






                        <div class="disclaimerbg">
                          <div class="row">
                            <div class="col-md-1 confirm-discseat">Disclaimer&nbsp;:</div>
                            <div class="col-md-11 confirm-seat-disc"> All Price are quoted in USD. Your credit/debit card may be billed in multiple charges totaling the final total price. Baggage allowance and fee
                              amount are not guaranteed and are subject to change by the airline.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="cnfr-marb15">
                      <div class="confirm-title">
                        <h5><img src={userIcon} alt="userIcon" />
                          Flight Booking Terms & Policies</h5>
                      </div>
                      <div class="confirm-boxsadow">
                        <h5>Booking Policies</h5>
                        <div class="tmcaply">
                          <ul style={{ marginBottom: '10px' }}>
                            <li>Above itinerary and agency reference number is for information only. Your credit card payment is under process.</li>
                            <li>You would receive your E-tickets and airlines confirmation in a separate email.</li>

                            <li> Your tickets would be issued once the payment is charged Fares are not guaranteed until the payment is charged.</li>

                            <li>Travodeals do not hold any responsibility for the fare or tax difference while the tickets are in issuance process.</li>

                            <li>Any fare or tax difference has to pay by the passenger before the tickets are issued</li>

                            <li>If there is a problem in processing the payment, you would be notified instantly. in case you do not receive an email or call within hours of making this reservation. Then please visit us or call us on the following number www.domain ( {displayNumber} )</li>

                            <li>We strongly recommend all travelers to confirm the status of their flight at least 72 hours before departure.
                            </li>

                          </ul>
                        </div>

                        <div class="disclaimerbg">
                          <h5 style={{ textAlign: 'center', marginBottom: '5px' }}>For immediate help on your booking, please call us at {displayNumber}</h5>
                        </div>

                      </div>
                    </div>




                  </div>

                  <div class="col-md-3" style={{ marginTop: '65px' }}>
                    <div class="cnfr-marb15 is-sticky">
                      <div class="confirm-boxsadow">
                        <div class="booked-fare-area" style={{ background: `url(${getBackgroundImage()}) center center/cover no-repeat`, height: '640px', paddingTop: '62px' }}>
                          <ul>
                            <li>Trustworthy experts always ready to assist you</li>
                            <li>Best flight deals for smart travelers available</li>
                            <li>Dive into our curated popular destination list.</li>
                            <li>Cancel with ease in the first 24-hours</li>
                            {/* <li>Confirm your trip with speedy booking assurance</li> */}
                          </ul>
                        </div>
                      </div>

                      {/* <div class="confirm-boxsadow">
                        <img src={background2} style={{ width: '100%' }} alt="background" />
                      </div> */}

                    </div>


                  </div>

                </div>

              </div>
            </div>
          ) : (<p>No data received</p>)}
        </div>

      </div>
      <Footer2 />
    </>
  )
}
