/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {BrowserRouter, Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import '../resources/css/airline-style.css';
//import backTab from '../resources/images/tabsbg.png';
import tabsArrow from '../resources/images/tabsarow.png'
import Arror from '../resources/images/icons/arowimg.png'
import Searchbar from '../Shared/Searchbar';
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import DealsGlobe from '../resources/images/globepine.png';
import SITE_CONFIG from '../SiteController/SiteController';



function formatDateAndTime(isoDate) {
  const dateOptions = {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const formattedDate = new Date(isoDate);
  const formattedDateString = new Intl.DateTimeFormat('en-US', dateOptions).format(formattedDate);
  const formattedTimeString = new Intl.DateTimeFormat('en-US', timeOptions).format(formattedDate);

  return { formattedDateString, formattedTimeString };
}

export default function DestinationDetails() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const location = useLocation();
  const { response1, response2 } = location.state || {};
  const [utmParams, setUtmParams] = useState({});
  ////console.log(location.state)

  const { airlineName } = useParams();

// Split the airlineName using the hyphen separator to get individual parts
const parts = airlineName.split('-');

// Get the last part as the URL
const pageValue = parts.pop();

// Get the remaining parts as the Page Value
const url = parts.join('-');
  const [firstResponseData, setFirstResponseData] = useState(response1?.response[0] || null);
  const [secondResponseData, setSecondResponseData] = useState(response2 || null);
  const [reloadData, setReloadData] = useState({
    pageType: "City",
    pageValue: pageValue,
    siteId: siteID,
    url: url
  })

  const fetchData = async () => {
    
	////console.log(reloadData)
		try {
		  let response;
		  if (!response1) {
			response = await axios.post(`${apiIP}:9090/api/Airlines-Details`, reloadData);
			// Set firstResponseData state with the response if response1 is null
			if (response.status === 200) {
			  setFirstResponseData(response.data.response[0]);
			  ////console.log("Response from the first API:", response.data);
			  ////console.log(deals)
			  const response2 = await axios.post(`${apiIP}:9191/api/Airlines-Routes`,
			  { froCity:"",
			  toCity:"",
			  airline: "",
			  cabinClass:"Y",
			  tripType:"2",
			  page:"",
			  currency:"USD",
			  siteId: siteID,
			  departDate:"",
			  countryName:"US",
			  countryCode:"US",
			  cityName:"" }
			  );
			  setSecondResponseData(response2.data);
			  ////console.log("responswe23",response2);
			}
				
				
		  } else {
			// Use the existing response1 if available
			setFirstResponseData(response1.response[0]);
		  }
		  ////console.log(response);
		  // Update state with response data if needed
		} catch (error) {
		  ////console.error('Error fetching data:', error);
		  // Handle errors appropriately
		}
	  };
	
	  useEffect(() => {
		// Check if response1 or response2 is null, then call fetchData
		if (!response1 || !response2) {
		  fetchData();
		}
	  }, [url, pageValue, response1, response2]);

  const [selectedFlight, setSelectedFlight] = useState();
  useEffect(() => {
    if (secondResponseData && secondResponseData.response.length > 0) {
      setSelectedFlight(secondResponseData.response[0]);
    }
  }, [secondResponseData]);

  const handleFroCityClick = (item) => {
    setSelectedFlight(item);
  };

  const imageNames = ['albuquerque.webp', 'amsterdam.webp','atlanta.webp', 'chicago.webp', 'los-angeles.webp', 'mexico-city.webp', 'miami.webp', 'new-york.webp', 'san-francisco.webp', 'washington.webp', 'las-vegas.webp', , 'boston.webp', 'cancun.webp', 'barcelona.webp', 'orlando.webp', 'seattle.webp', 'san-jose.webp', 'dallas.webp', 'denver.webp', 'london.webp', 'paris.webp', 'rome.webp', 'tokyo.webp'];
    const filteredImages = imageNames
    .filter(name => name !== `${url}.webp`)
    .slice(0, 3); // Limiting to 3 images

    const imageNameToAirline = {
      'albuquerque.webp': 'albuquerque',
      'amsterdam.webp': 'amsterdam',
      'atlanta.webp': 'atlanta',
      'chicago.webp': 'chicago',
      'los-angeles.webp': 'los-angeles',
      'mexico-city.webp': 'mexico-city',
      'miami.webp': 'miami',
      'new-york.webp': 'new-york',
      'san-francisco.webp': 'san-francisco',
      'washington.webp': 'washington',
      'las-vegas.webp': 'las-vegas',
      'hawaii.webp': 'hawaii',
      'charleston.webp': 'charleston',
      'boston.webp': 'boston',
      'cancun.webp': 'cancun',
      'barcelona.webp': 'barcelona',
      'orlando.webp' : 'orlando',
      'seattle.webp' : 'seattle',
      'san-jose.webp' : 'san-jose',
      'dallas.webp' : 'dallas',
      'denver.webp' : 'denver',
      'london.webp' : 'london',
      'paris.webp' : 'paris',
      'rome.webp' : 'rome',
      'tokyo.webp' : 'tokyo'
    };
    const imageNameToAirlineCode = {
      'albuquerque.webp': 'abq',
      'amsterdam.webp': 'ams',
      'atlanta.webp': 'atl',
      'chicago.webp': 'ord',
      'los-angeles.webp': 'lax',
      'mexico-city.webp': 'mex',
      'miami.webp': 'mia',
      'new-york.webp': 'nyc',
      'san-francisco.webp': 'sfo',
      'washington.webp': 'was'
    };

    const handleSideClick = (pageValue, url) => {
      setReloadData({
        ...reloadData,
        pageValue,
        url,
      });
    };

    const getAirlineCode = (name) => {
      // Define a mapping of airline names to their respective codes
      const airlineCodes = {
        "Albuquerque": "ABQ",
        "Barcelona": "BCN",
        "Boston": "BOS",
        "Cancun": "CUN",
        "Charleston": "CHS",
        "Hawaii": "HI",
        "Chicago": "ORD",
        "Las Vegas": "LAS",
        "Orlando": "MCO",
        "Seattle": "SEA",
        "San Jose": "SJC",
        "San Francisco": "SFO",
        "Dallas": "DFW",
        "Denver": "DEN",
        "Honolulu": "HNL",
        "Kahului": "OGG",
        "Los Angeles": "LAX",
        "Guadalajara": "GDL",
        "London": "LON",
        "Cuncun": "CUN",
        "New York": "NYC",
        "Paris": "PAR",
        "Toronto": "YYZ",
        "Rome" : "ROM",
        "Tokyo" : "HND",
        "Florida" : "FL",
        "Vancouver" : "YVR",
        "Sydney" : "SYD",
        "South Island": "CHC",
        "Newport" : "ONP",
        "Louisville": "SDF",
        "Washington" : "WAS",
        "Westchester": "HPN"
      };
    
      return airlineCodes[name];
    };
    
    // Function to create a URL with the airline code appended to the airline name
    const createURL = (name) => {
      const airlineCode = getAirlineCode(name);
      return `https://www.rawfares.com/flights-to/${name.toLowerCase().replace(/\s+/g, '-')}-${airlineCode.toLowerCase()}`;
    };

    const createURLNew = (name) => {
      const airlineCode = getAirlineCode(name);
      return `https://www.rawfares.com/flights/cheap-flights-to-${name?.toLowerCase().replace(/\s+/g, '-')}-${airlineCode?.toLowerCase()}`;
    };

    const createURLRoute = (origin, destination) => {
      const originCode = getAirlineCode(origin);
      const destinationCode = getAirlineCode(destination);
      return `https://www.rawfares.com/flights/cheap-flights-from-${origin?.toLowerCase().replace(/\s+/g, '-')}-to-${destination?.toLowerCase().replace(/\s+/g, '-')}-${originCode?.toLowerCase()}-${destinationCode?.toLowerCase()}`;
    };

	const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: siteID,
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      //const [responseData, setResponseData] = useState(null);
      //const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
    

const handleAirlineClicked = async () => {

    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        ////console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      ////console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const standardizeAirlineName = (name) => {
        // Define a mapping of variations to a standard name
        const nameVariations = {
          "Albuquerque": "ABQ",
          "Barcelona": "BCN",
          "Boston": "BOS",
          "Cancun": "CUN",
          "Charleston": "CHS",
          "Hawaii": "HI",
          "Chicago": "ORD",
          "Las Vegas": "LAS",
          "Orlando": "MCO",
          "Seattle": "SEA",
          "San Jose": "SJC",
          "San Francisco": "SFO",
          "Dallas": "DFW",
          "Denver": "DEN",
          "Honolulu": "HNL",
          "Kahului": "OGG",
          "Los Angeles": "LAX",
          "Guadalajara": "GDL",
          "London": "LHR",
          "Cuncun": "CUN",
          "New York": "JFK",
          "Paris": "PAR",
          "Toronto": "YYZ",
          "Rome" : "ROM",
          "Tokyo" : "HND",
          "Florida" : "FL",
          "Vancouver" : "YVR",
          "Sydney" : "SYD",
          "South Island": "CHC"
          // Add more variations as needed
        };
      
        return nameVariations[name] || name; // If a variation is found, return the standardized name, otherwise return the original name
      };
      
      // Apply the standardization to create an array of standardized airline names from filteredImages
      const airlineNamesFromImages = filteredImages.map(imageName => standardizeAirlineName(imageNameToAirline[imageName]));


      const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const storeUtmData = () => {
      const queryParams = new URLSearchParams(window.location.search);
      console.log("queryParams", queryParams)
      const utmData = {};
  
      // Iterate through query parameters and extract UTM parameters
      for (let [key, value] of queryParams.entries()) {
        if (key.startsWith("utm_")) {
          utmData[key] = value;
        }
      }
  
      if (Object.keys(utmData).length > 0) {
        const expirationTime = new Date().getTime() + 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
        const dataWithExpiration = {
          utmData,
          expiresAt: expirationTime,
        };
  
        // Store UTM data in localStorage
        localStorage.setItem("utm_data", JSON.stringify(dataWithExpiration));
        setUtmParams(utmData); // Update state
      }
    };
  
    // Execute on query parameter change
    storeUtmData();
  }, [location.search]);

  return (
    <>
	<MetaTitle pageTitle={firstResponseData?.metaTitle} pageDescription={firstResponseData?.metaDesc} url={`${apiIP}/flights-to/${airlineName}`} />
  {firstResponseData && firstResponseData.pageName && (
    <Searchbar backgroundImage={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationcover_banner/${url}.webp`} headText={`Cheap Flights to ${firstResponseData?.pageName}`} textColor={'white'} />
  )}
    <div class="dels-sction">	
 <div class="best-cntnr">
	 
    <div class="col-first">
	 <h1>Flights to {firstResponseData?.pageName}({firstResponseData?.pageValue && firstResponseData.pageValue.toUpperCase()})</h1>
	<div dangerouslySetInnerHTML={{ __html: firstResponseData?.contentData }}></div>
    </div>
	 
    <div class="col-last">
	{filteredImages.map((imageName, index) => (
        <div className="sidebox" key={index}>
          <div onClick={() => handleCardClick("City", imageNameToAirlineCode[imageName], imageNameToAirline[imageName])} style={{cursor: 'pointer'}}>
            <img
              src={`https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationside_banner/${imageName}`}
              alt={imageNameToAirline[imageName]}
            />
          </div>
        </div>
      ))}
		
    </div>
	 
    <div class="full-width">
    {windowWidth > 430 && (
	<div class="tabgcolor">
<h3>Flight Deals from {secondResponseData?.response[0]?.froCityName} to{" "}
                    {secondResponseData?.response[0]?.toCityName}</h3>
<div class="maintabs" style={{height: 'auto', backgroundImage: `url(https://imgfolders.com/rawfares/rawfaresUS/airline_detail/destinationcover_banner/${url}.webp)`, backgroundSize: 'cover'}}>
	
<div class="tab">
  <div class="tabs_heading">Flight From</div>	
  {secondResponseData && secondResponseData?.response && Array.isArray(secondResponseData?.response) ? (
    secondResponseData?.response.map((item, index) => (
  <button class={`linkstab ${item === selectedFlight ? 'selected' : ''}`} key={index} onClick={() => handleFroCityClick(item)}>
	  <img src={DealsGlobe} alt="DealsGlobe"/> {item.froCityName}
	<span class="tbarow"><img src={tabsArrow}  alt="tabsArrow"/></span>
	</button>
 ))
 ) : (
   <p>No responses available</p>
 )}
	{/* <!--//////////More Button////////////--> */}
	
</div>
	{/* <!--Tabs--> */}
  {selectedFlight ? (
<div id="newyorkcity" class="bottomright">
  <h3 style={{color: '#fff'}}>Fly from {selectedFlight.froCityName}</h3>
	<div class="cityfaremain">  
	<div class="citydate">
	<p>Depart Date-{formatDateAndTime(selectedFlight.depDate).formattedDateString}</p>
	<p>Return Date-{formatDateAndTime(selectedFlight.retDate).formattedDateString}</p>
	</div>	  
	<div class="city-fare">
	<p>Fare*</p>
	<p>${selectedFlight.totalPrice} {/*<sup>00</sup>*/}</p>	
	</div>
	</div> 
  </div>	
  ) : (
    <p>Select a city to view details.</p>
  )}

<div style={{clear: 'both'}}></div>	
</div>	

<div style={{clear: 'both'}}></div>
<p class="dismer"><span>Disclamer:-</span> All fares on rawfares.com are quoted in USD. These fare have Originated from historical data. Might vary and cannot be promised at the time of booking.</p>
		</div> 
    )}
		
	  <h3 style={{paddingTop: '20px'}}>{firstResponseData?.airportHub}</h3>
		
	  <div dangerouslySetInnerHTML={{ __html: firstResponseData?.travelTrends }}></div>
		
      <div className="d-md-flex justify-content-between w-100">
      <div class="side_airs wish-side-sir">
  <h4>Top Flights Domestic</h4>
  <ul>
  {["Dallas", /*"Delta Air Lines" */ "Boston", "Charleston", "Hawaii", "Chicago", "Las Vegas", "Orlando", "Seattle", "San Jose", "Florida"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURLNew(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
<div className="side_airs wish-side-sir">
  <h4>Top Flight Routes</h4>
  <ul>
    {[
      { origin: "Boston", destination: "Newport" },
      { origin: "Los Angeles", destination: "Chicago" },
      { origin: "Denver", destination: "Chicago" },
      { origin: "Honolulu", destination: "Kahului" },
      { origin: "Las Vegas", destination: "Los Angeles" },
      { origin: "Louisville", destination: "Chicago" },
      { origin: "Los Angeles", destination: "San Francisco" },
      { origin: "New York", destination: "Washington" },
      { origin: "Seattle", destination: "Boston" },
      { origin: "Westchester", destination: "Chicago" }
      // Add more origin-destination pairs
    ].map(({ origin, destination }) => (
      <li key={`${origin}-${destination}`}>
        <Link
          to={createURLRoute(origin, destination)}
          target="_blank"
          onClick={() => fetchData()}
        >
          From {origin} to {destination} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  </ul>
  {/* <button>View More</button> */}
</div>
<div class="side_airs wish-side-sir">
  <h4>Top International Flights</h4>
  <ul>
  {["Barcelona", /*"Delta Air Lines"*/  "Cancun", "London", "Paris", "Rome", "Tokyo", "Toronto", "Vancouver", "Sydney", "South Island"].filter(airlineName => (
      // Check if the airline name is not equal to firstResponseData.pageName and not present in airlineNamesFromImages
      firstResponseData?.pageName !== airlineName && !airlineNamesFromImages.includes(airlineName)
    )).map(airlineName => (
      <li key={airlineName}>
        <Link to={createURLNew(airlineName)} target='_blank' onClick={() => fetchData()}>
          {airlineName} <img src={Arror} alt="arowimg" />
        </Link>
      </li>
    ))}
  
  </ul>
</div>
      </div>

    </div> 
         
        </div>
        
	
    </div>
   <div style={{clear: 'both'}}></div>
   <Footer dealType={'Flights'} />
    </>
  )
}
