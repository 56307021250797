/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

export default function Footer2() {
  const [utmCampaign, setUtmCampaign] = useState("");
  const [displayNumber, setDisplayNumber] = useState("");
  const jsonMapping = {
          domestics_routes: "+1-833-609-8333",
          domestics_destination: "+1-833-609-7300",
          cruise : "+1-833-609-9933",
          airline: "+1-833-609-8444"
        };
        const defaultNumber = "+1-888-415-1536"; // Default number to display if UTM data is not found
  
        useEffect(() => {
          const updateDisplayNumber = () => {
            const utmData = JSON.parse(localStorage.getItem("utm_data")) || {};
          //   console.log(utmData)
            if (utmData?.utmData?.utm_campaign) {
              // console.log("in")
              setUtmCampaign(utmData?.utm_campaign);
      
              // Check if `utm_campaign` exists in the JSON mapping
              const mappedNumber = jsonMapping[utmData?.utmData?.utm_campaign];
              setDisplayNumber(mappedNumber || defaultNumber); // Use mapped number or default
            } else {
              // console.log("out")
              // No UTM data found, use the default number
              setDisplayNumber(defaultNumber);
            }
          };
      
          // Run initially
          updateDisplayNumber();
      
          // Set up interval to check every 10 seconds
          const interval = setInterval(updateDisplayNumber, 1000000000000);
      
          // Clean up interval on component unmount
          return () => clearInterval(interval);
        }, [jsonMapping]);
  return (
    <>
    <div className="copyright-block">
        <div className="container">
        <p style={{textAlign: "center ", color: 'white', fontSize: '13px'}}>Rawfares is represented by Holiay Breakz Group LLC which is registered in 5830 N Valentine Ave #102, Fresno, CA 93711 USA<br/>Rawfares.com is an independent travel portal with no third party association. By using Rawfares.com, you agree that Rawfares is not accountable for any loss - direct or indirect, arising of offers, materials or links to other sites found on this website. In case of queries, reach us directly at our Contact Number {displayNumber} or, simply email at support@rawfares.com</p>
            <div className="copyright"> Copyrights &copy; 2022-2025 rawfares.com, All Rights Reserved. </div>
        </div>
    </div>
    <div class="visible-xs">
    <div class="bottom-call-bar2" style={{bottom: '0px', fontFamily: 'Arial, Helvetica, sans-serif'}}>    
    <a id="a_contactNo" href="tel:+1-888-415-1536">
      <div class="call-text">Call &amp; Get Unpublished Flight Deals!</div>
      <div class="PH_contactNo2">{displayNumber}</div>
      </a> </div>
  </div>
  </>
  )
}
