/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import '../resources/css/sitemap.css';
import tnc from '../resources/images/icons/tnc-icon.svg';
import Footer from '../components/Footer/footer';
import MetaTitle from './metaTitle';
import SITE_CONFIG from '../SiteController/SiteController';
import axios from "axios";

export default function Sitemap() {
  const { apiIP, siteName, siteID } = SITE_CONFIG;
  const [domesticAirports, setDomesticAirports] = useState([]);
  const [internationalAirports, setInternationalAirports] = useState([]);
  const [domesticAirportsRoute, setDomesticAirportsRoute] = useState([]);
  const [internationalAirportsRoute, setInternationalAirportsRoute] = useState([]);
  const [dealsData, setDealsData] = useState([])
  const [airlines, setAirlines] = useState({
    pageType: "Airline",
    pageValue: "",
    siteId: siteID,
    url: ""
  });

  const [deals, setDeals] = useState({
    froCity: "",
    toCity: "",
    airline: airlines.pageValue,
    cabinClass: "Y",
    tripType: "2",
    page: "",
    currency: "USD",
    siteId: siteID,
    departDate: "",
    countryName: "US",
    countryCode: "US",
    cityName: ""
  });
  const [responseData, setResponseData] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    // Use a useEffect to make the API call whenever airlines state changes
    if (airlines.pageValue && airlines.url) {
      handleAirlineClicked();
    }
  }, [airlines]);

  const handleAirlineClicked = async () => {

    const navigationPath =
      airlines.pageValue === "flight"
        ? `/deals/${airlines.url}-${airlines.pageValue}`
        : airlines.pageValue.length === 2
          ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
          : `/flights-to/${airlines.url}-${airlines.pageValue}`;


    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
      //console.log('Received data in new tab:');
      newTab.onload = function () {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };



  const handleCardClick = (pageType, pageValue, url) => {
    // Set the new values for airlines based on the card clicked
    setAirlines({
      ...airlines,
      pageType,
      pageValue,
      url,
    });
  };

  const openLink = (url) => {
    window.open(url, '_blank', 'width=650,height=550,left=300,top=50');
  };

  const sitemapRef = useRef(null);

  // Handle the scroll when the "View More" button is clicked
  const handleScrollToSitemap = () => {
    if (sitemapRef.current) {
      sitemapRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const handleAllData = async () => {
    const payloadFirst = {
      pageType: "City_PPC",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${apiIP}:1110/api/sitemap`, payloadFirst);
  
      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirports = [];
        const internationalAirports = [];
        const seenCities = new Set(); // Track unique city names
        console.log("CityPPC", apiResponse.data.response)
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const fromCountry = item.fromCountry ? item.fromCountry.trim().toUpperCase() : null;
          const category = fromCountry === "US" ? "Domestic" : "International";
  
          if (!seenCities.has(item.url.trim())) {
            seenCities.add(item.url.trim());
            
            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };
  
            // Categorize the airport
            if (category === "Domestic") {
              domesticAirports.push(airportWithUrl);
            } else {
              internationalAirports.push(airportWithUrl);
            }
          }
        });
  
        // Log and update state
        console.log("Domestic Airports:", domesticAirports);
        console.log("International Airports:", internationalAirports);
        setDomesticAirports(domesticAirports);
        setInternationalAirports(internationalAirports);
  
        // Return or use the lists as needed
        return { domesticAirports, internationalAirports };
      } else {
        console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };
  

  const handleAllDataRoute = async () => {
    const payloadFirst = {
      pageType: "Routes_PPC",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${apiIP}:1110/api/sitemap`, payloadFirst);
  
      if (apiResponse.status === 200) {
        // Initialize lists for domestic and international airports
        const domesticAirportsRoute = [];
        const internationalAirportsRoute = [];
        const seenCitiesRoute = new Set(); // Track unique city names
        console.log("apiResponse", apiResponse)
        // Process the API response data directly
        apiResponse.data.response.forEach((item) => {
          const toCountry = item.toCountry.trim().toUpperCase();
          const category = toCountry === "US" ? "Domestic" : "International";

          console.log(`Item: ${item.pageName}, toCountry: "${toCountry}", Category: ${category}`);
  
          if (!seenCitiesRoute.has(item.url.trim())) {
            seenCitiesRoute.add(item.url.trim());
  
            const airportWithUrl = {
              ...item,
              category,
              url: item.url, // Include the URL
            };
  
            // Categorize the airport
            if (category === "Domestic") {
              domesticAirportsRoute.push(airportWithUrl);
            } else {
              internationalAirportsRoute.push(airportWithUrl);
            }
          }
        });
  
        // Log and update state
        console.log("Domestic Airports:", domesticAirportsRoute);
        console.log("International Airports:", internationalAirportsRoute);
        setDomesticAirportsRoute(domesticAirportsRoute);
        setInternationalAirportsRoute(internationalAirportsRoute);
  
        // Return or use the lists as needed
        return { domesticAirportsRoute, internationalAirportsRoute };
      } else {
        console.log("Error: API response status is not 200");
      }
    } catch (error) {
      console.error("Error fetching sitemap data:", error.message);
    }
  };

  const handleDeals = async () => {
    const payloadFirst = {
      pageType: "Deals",
      siteId: "16",
    };
  
    try {
      const apiResponse = await axios.post(`${apiIP}:1110/api/sitemap`, payloadFirst);
      console.log(apiResponse)
      if (apiResponse.status === 200) {
        setDealsData(apiResponse.data.response)
      } else {} } catch {}
  }


  useEffect(() => {
    handleAllData();
    handleAllDataRoute();
    handleDeals();
  }, []);

  console.log("srhtshhsfg", domesticAirportsRoute)

  return (
    <>
      <MetaTitle pageTitle="Explore Sitemap of rawfares.com" pageDescription="Check the sitemap to get the details of flights and other information at rawfares.com" url={`${apiIP}/sitemap`} />
      <div class="container-fluid" >

        <div class="row">
          <div class="content_page_title">
            <div class="container">
              <h1><img src={tnc} alt="tnc" /> Sitemap</h1>
            </div>
          </div>

          <div class="textBox">
            <div class="container">
              <div class="row">
                <div class="col-sm-12">
                  {/* <div class="sitemap-wrap">
                        <h3 class="mt-0">Popular Deals</h3>
						<ul class="site_map_links">
							<li>Winter Travel Deals</li>
							<li>Thanksgiving Flight Deals</li>
							<li>Christmas Flight Deals</li>
							<li>New Year Flight Deals</li>
							<li>Military Travel Deals</li>
							<li>Last Minute Flights</li>
							<li>Student Flights Deals</li>
							<li>Senior Travel Deals</li>
					 </ul>
                     </div> */}
                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Quick Links</h3>
                    <ul class="site_map_links">
                      <li><Link to='/about'> About Us </Link></li>
                      <li><Link to='/contact-us'> Contact Us </Link></li>
                      <li><Link to={`${apiIP}/online-check-in`} >Online Check-in</Link></li>
                      <li><Link to={`${apiIP}/airline-baggage-fee`} onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href); }}>Airline Baggage Fees</Link></li>
                      <li><Link to='/post-ticketing-fee'>Post Ticketing Fees</Link></li>
                      <li><Link to='/our-services-fee' onClick={(e) => { e.preventDefault(); openLink(e.currentTarget.href); }}>Our Service Fees</Link></li>
                      <li><Link to='/contact-us'>Contact Us</Link></li>
                      <li> Privacy Policy </li>
                      <li><Link to='/terms-and-condition'> Terms &amp; Conditions </Link></li>
                      <li><Link to='/cruise'>Cruise </Link></li>

                    </ul>



                  </div>
                  <div class="sitemap-wrap" ref={sitemapRef}>
                    <h3 class="mt-0">Popular Airlines</h3>
                    <ul class="site_map_links" >
                      {/* <li><div onClick={(e) => {handleCardClick("Airline", "dl", "delta-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Delta Airlines</div></li> */}
                      {/* <li><div onClick={(e) => {handleCardClick("Airline", "wn", "southwest-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Southwest Airlines</div></li> */}
                      <li><div onClick={(e) => { handleCardClick("Airline", "ua", "united-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>United Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "as", "alaska-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Alaska Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "aa", "american-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>American Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "b6", "jetblue-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>JetBlue Airways</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "ha", "hawaiian-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Hawaiian Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "nk", "spirit-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Spirit Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "f9", "frontier-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Frontier Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "g4", "allegiant-air"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Allegiant Air</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "lh", "lufthansa-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Lufthansa Airlines</div></li>
                      <li><div onClick={(e) => { handleCardClick("Airline", "sy", "sun-country-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/ }} style={{ cursor: 'pointer' }}>Sun Country Airlines</div></li>
                      {/* <li><div onClick={(e) => {handleCardClick("Airline", "3m", "silver-airways"); /*e.preventDefault(); openLink(e.currentTarget.href);}} style={{cursor: 'pointer'}}>Silver Airways</div></li> */}
                    </ul>
                  </div>
                  {/* <div class="sitemap-wrap">
                     <h3 class="mt-0">Popular Routes</h3>
						<ul class="site_map_links">
							<li>Los Angeles to New York</li>
							<li>New York to Miami</li>
							<li>Chicago to New York</li>
							<li>New York to London</li>
							<li>New York to Paris</li>
					 </ul>
                    </div> */}

                  {/* <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular Domestic Flights</h3>
                    <ul class="site_map_links">
                     
                      <li><div onClick={(e) => handleCardClick("City", "nyc", "new-york")} style={{ cursor: 'pointer' }}>Flights to New York</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "lax", "los-angeles");  }} style={{ cursor: 'pointer' }}>Flights to Los Angeles</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "mia", "miami");  }} style={{ cursor: 'pointer' }}>Flights to Miami</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "atl", "atlanta"); }} style={{ cursor: 'pointer' }}>Flights to Atlanta</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "ord", "chicago"); }} style={{ cursor: 'pointer' }}>Flights to Chicago</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "was", "washington"); }} style={{ cursor: 'pointer' }}>Flights to Washington</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "sfo", "san-francisco");}} style={{ cursor: 'pointer' }}>Flights to San Francisco</div></li>
                      <li><div onClick={(e) => { handleCardClick("City", "abq", "albuquerque"); }} style={{ cursor: 'pointer' }}>Flights to Albuquerque</div></li>
                    </ul>
                  </div> */}
                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular Domestic Flights</h3>
                    <ul class="site_map_links">
                      {domesticAirports.map((item, index) => (
                        <li key={index}><a href={`/flights/${item.url}`} target='_blank'>Flights to {item.pageName}</a></li>
                      ))}
                    </ul>
                  </div>

                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular Domestic Routes</h3>
                    <ul class="site_map_links">
                      {domesticAirportsRoute.map((item, index) => (
                        <li key={index}><a href={`/flights/${item.url}`} target='_blank'>Flights from {item.pageName} to {item.toCity}</a></li>
                      ))}
                    </ul>
                  </div>

                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular International Flights</h3>
                    <ul class="site_map_links">
                      {internationalAirports.map((item, index) => (
                        <li key={index}><a href={`/flights/${item.url}`} target='_blank'>Flights to {item.pageName}</a></li>
                      ))}
                    </ul>
                  </div>

                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular International Routes</h3>
                    <ul class="site_map_links">
                      {internationalAirportsRoute.map((item, index) => (
                        <li key={index}><a href={`/flights/${item.url}`} target='_blank'>Flights from {item.pageName} to {item.toCity}</a></li>
                      ))}
                    </ul>
                  </div>

                  <div class="sitemap-wrap">
                    <h3 class="mt-0">Popular Deals</h3>
                    <ul class="site_map_links">
                      {/* <li><div onClick={(e) => { handleCardClick("deals", "flight", "military") }} style={{ cursor: 'pointer' }}>Military Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "summer"); }} style={{ cursor: 'pointer' }}>Summer Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "student"); }} style={{ cursor: 'pointer' }}>Student Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "senior"); }} style={{ cursor: 'pointer' }}>Senior Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "honeymoon"); }} style={{ cursor: 'pointer' }}>Honeymoon Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "last-minute"); }} style={{ cursor: 'pointer' }}>Last Minute Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "business-class"); }} style={{ cursor: 'pointer' }}>Business Class Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "lgbtq"); }} style={{ cursor: 'pointer' }}>LGBTQ Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "domestic"); }} style={{ cursor: 'pointer' }}>Domestic Flight</div></li>
                      <li><div onClick={(e) => { handleCardClick("deals", "flight", "international"); }} style={{ cursor: 'pointer' }}>International Flight</div></li> */}
                      {dealsData.map((item, index) => (
                        <li key={index}><a href={`/deals/${item.url}`} target='_blank'>{item.pageName}</a></li>
                      ))}
                    </ul>
                  </div>


                </div>
              </div>
            </div>
          </div>



        </div>

      </div>
      <Footer dealType={'Flights'} />
    </>
  )
}
