/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import SITE_CONFIG from '../SiteController/SiteController';
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import Loader from '../resources/loader/loader.gif';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../resources/css/search_engine.css';
import '../resources/css/bootstrap.min.css';
import '../resources/css/jquery-ui.css';
import '../resources/css/fontawesome.all.min.css';
import '../resources/images/favicon.png';
import '../resources/font/bootstrap-icons.css';
import Footer from '../components/Footer/footer'
//import '../resources/jc/owl.carousel.min.css';
//import '../resources/js/jquery.meanmenu.js';
//import '../resources/js/custom.js';
//import '../resources/js/jquery.js';
import '../resources/jc/wow.min.js';
//import '../resources/jc/owl.carousel.min.js';
//import '../resources/jc/main.js';
import swap from '../resources/images/icons/swap-icon.svg';
import maps from '../resources/images/icons/maps.png';
import sale from '../resources/images/icons/sale.png';
import customerService from '../resources/images/icons/customer-service.png';
import destination1 from '../resources/images/home_Destination/Miami.png'
import destination2 from '../resources/images/home_Destination/San-Francisco.png';
import destination3 from '../resources/images/home_Destination/New-York.png';
import imgAirline1 from '../resources/images/air-deals/img-airlines.jpg';
import imgAirline2 from '../resources/images/air-deals/img-airlines2.jpg';
import imgAirline3 from '../resources/images/air-deals/img-airlines3.png';
import imgAirline4 from '../resources/images/air-deals/img-airlines4.jpg';
import imgAirline5 from '../resources/images/air-deals/img-airlines5.png';
import imgAirline6 from '../resources/images/air-deals/img-airlines6.jpg';
import demoImage from '../resources/images/demoImage1.jpg';
import demoImage1 from '../resources/images/demoimage2.jpg'
import Searchbar from '../Shared/Searchbar';
import MetaTitle from './metaTitle.jsx';
import CookieNotice from './CookieNotice.jsx';
import closeBtn from'../resources/images/banner/close.png';
import popupBanner from '../resources/images/banner/popup.jpg';
import roundPopup from '../resources/images/banner/round-trip.png';
import phone3 from '../resources/images/banner/phone3.gif'


export default function Home() {
    const { apiIP, siteName, siteID } = SITE_CONFIG;
    const [isLoading, setIsLoading] = useState(false);
    const [ bannerDisplay, setBannerDisplay ] = useState(false);
    const [ closeBanner, setCloseBanner ] = useState(false) ; 
    const [airlines, setAirlines] = useState({
        pageType: "Airline",
        pageValue: "",
        siteId: siteID,
        url: ""
      });

      const [deals, setDeals] = useState({
        froCity:"",
        toCity:"",
        airline: airlines.pageValue,
        cabinClass:"Y",
        tripType:"2",
        page:"",
        currency:"USD",
        siteId: siteID,
        departDate:"",
        countryName:"US",
        countryCode:"US",
        cityName:""
    });
      const [responseData, setResponseData] = useState(null);
      const navigate = useNavigate();
      useEffect(() => {
        // Use a useEffect to make the API call whenever airlines state changes
        if (airlines.pageValue && airlines.url) {
          handleAirlineClicked();
        }
      }, [airlines]);
   
const handleAirlineClicked = async () => {

    const navigationPath = airlines.pageValue.length === 2
      ? `/airline/${airlines.url}-${airlines.pageValue}-flights`
      : `/flights-to/${airlines.url}-${airlines.pageValue}`;

    const newTab = window.open(navigationPath, '_blank');
    if (newTab) {
        //console.log('Received data in new tab:');
      newTab.onload = function() {
        newTab.postMessage(window.location.origin);
      };
    } else {
      //console.error('Failed to open new tab. Please check your browser settings or extensions.');
    }

    // Navigate to the new page with data passed via state
    //navigate(navigationPath);
  };
      
      
    
      const handleCardClick = (pageType, pageValue, url) => {
        // Set the new values for airlines based on the card clicked
        setAirlines({
          ...airlines,
          pageType,
          pageValue,
          url,
        });
      };

      const openLink = (url) => {
		window.open(url, '_blank');
	  };
  return (
    <>
    <div className='HomeBody'>
        {isLoading && (
        <div className="loader-overlay">
          <img src={Loader} alt="Loader" />
        </div>
      )}
      <Helmet>
        <title>Exceptional Flight & Hotel Deals | RawFares</title>
        <meta name="description" content="RawFares offers unbeatable travel deals. Connect with our team to get affordable travel options." />
        {/* Other meta tags */}
        <link rel="canonical" href={`${apiIP}/`} />
        {/* Additional meta tags for homepage */}
      </Helmet>
      {/* <MetaTitle pageTitle="Cheap Flights | Airline Tickets | Airfare & Discount Deals | Rawfares" pageDescription="Find Cheap Flights, book discount airfares and air tickets, direct & last-minute deals available on exclusive discount offer." url={`${apiIP}/`} /> */}
    < Searchbar backgroundImage="https://imgfolders.com/rawfares/rawfaresUS/home_banner/cover.webp" />
        <div className="relative">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="dealBox_image">
                            <h2 className="page_title">Most Popular Domestic Destinations</h2>
                            <p>From the iconic cityscapes of New York to the sun-kissed beaches of Bali, these destinations have captured the hearts of travelers worldwide. Immerse yourself in the cultural tapestry of Paris, explore the historic wonders of Rome, or unwind in the tropical paradise of the Maldives. Whether you seek vibrant urban adventures or serene natural retreats, our handpicked destinations at RawFares promise unforgettable experiences. Let your travel dreams take flight as you discover the world's most sought-after and captivating locales.</p>
                        </div>
                    </div>
                </div>
                <div className="row g-3 destination" style={{marginBottom: "20px"}}>
                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated" onClick={(e) => {handleCardClick("City", "mia", "miami"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}}>
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("mia", "miami"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUS/home_destination/Miami.webp`} alt="destination" style={{height: '237px'}} onClick={() => handleCardClick("mia", "miami")} />
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">$150.<sup>99</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">Miami</div>
                        </div>
                        
                    </div>       
                    

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "sfo", "san-francisco"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUS/home_destination/San-Francisco.webp`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">$230.<sup>89</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">San Francisco</div>
                        </div>
                        
                    </div>

                    <div className="col-lg-4 col-md-12 wow zoomIn animated animated">
                        <div className="position-relative d-block overflow-hidden" onClick={(e) => {handleCardClick("City", "nyc", "new-york"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                            <img className="img-fluid" src={`https://imgfolders.com/rawfares/rawfaresUS/home_destination/New-York.webp`} alt="destination" style={{height: '237px'}}/>
                            {/* <div className="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">25% OFF</div> */}
                            <div class="bg-white text-danger fw-bold position-absolute top-0 start-0 m-3 py-1 px-2">$90.<sup>67</sup></div>
                            <div class="bg-white text-primary fw-bold position-absolute bottom-0 end-0 m-3 py-1 px-2">New York</div>
                        </div>
                        
                    </div>
                <p style={{fontSize: '11px', color: '#959595', fontFamily: 'sans-serif'}}><b>Note:</b> All fares are quoted in USD.
                    Last updated on Thursday 01/08/2025 at 05:00 AM, the fares mentioned above are for Round Trip flight tickets and inclusive of fuel surcharges, service fee and taxes . Based on historical data, these fares are subject to change without prior notice and cannot be guaranteed at the time of booking. Kindly go through our terms and conditions before booking
                </p>
                </div>
                </div>

            <div className="box-text grayBg" style={{marginBottom: '2px'}}>
                <div className="container">
                    <div className="row">
                        <div className="holder">
                            <div className="heading">
                                <h2>Top Airlines Deals</h2>
                                <p>Unlock a realm of possibilities as we bring you handpicked deals from leading airlines such as Spirit, United, and American Airlines, ensuring your flights are not just a means of transportation but a gateway to extraordinary experiences. Whether you're chasing sunsets on a tropical beach or exploring historic cities, our curated airline deals promise to elevate your travel experience. Fly with confidence, knowing that your journey is not just a ticket but a seamless passage to the destinations that await your exploration. Discover more, pay less—because your next adventure should be as boundless as your dreams.</p>
                            </div>
                            <OwlCarousel className='owl-theme' loop margin={10} >
                                <div className="project-item mb-1">
                                <div onClick={(e) => {handleCardClick("Airline", "aa", "american-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={demoImage} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>American Airlines</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "ua", "united-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={demoImage1} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>United Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "nk", "spirit-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUS/home_airline/img-airlines3.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Spirit Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "lh", "lufthansa-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUS/home_airline/img-airlines4.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Lufthansa Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "ha", "hawaiian-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUS/home_airline/img-airlines5.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Hawaiian Airlines</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="project-item mb-1">
                                    <div onClick={(e) => {handleCardClick("Airline", "f9", "frontier-airlines"); /*e.preventDefault(); openLink(e.currentTarget.href);*/}} style={{cursor: 'pointer'}}>
                                        <div className="deals_img">
                                            <img src={`https://imgfolders.com/rawfares/rawfaresUS/home_airline/img-airlines6.webp`} alt="img" className="img-responsive"/>
                                        </div>
                                        <div className="deals_heading">
                                            <p>Frontier Airlines</p>

                                        </div>
                                    </div>
                                </div>
                            </OwlCarousel>
                            
                        </div>
                        
                    </div>
                    
                </div>
                
            </div>
            
            <div className="clearfix"></div>
            {/* <!-- Text Box Start here --> */}
            <div className="box-text">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <h1>Discovering the Most Affordable Flights Anywhere, Simplified!</h1>
                            <p>Welcome to RawFares.com, your ultimate destination for all things travels. At RawFares, we take pride in democratizing travel affordability for countless explorers. Our cutting-edge search tool ensures that trimming down your airline expenses is as easy as a few taps.</p><br/>
                            <h3>Get 24/7 Assistance for Your Journey</h3>
                            <p>Around the clock, our squad of travel enthusiasts collaborates with a myriad of airlines and premier travel platforms. Whether you're planning a romantic getaway or embarking on a spontaneous business expedition, rest assured that fretting over flight costs is a thing of the past. At RawFares, we're not just facilitating journeys; we're unlocking a world where your travel dreams meet budget-friendly reality.</p>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Text Box End here --> */}
            <div className="clearfix"></div>
        </div>
        <Footer dealType={'Flights'} />
        </div>
        <CookieNotice />
        </>
  )
}
